@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@300;500&display=swap);
* {
  /* font-weight: 100 !important; */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

.left h2{
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 5px;
}

.test{
  display: inline !important;
}

.event img{
  border-radius: 20px;
  margin-bottom: 25px;
}

.event h3{
  font-size: 24px;
  font-weight: 600;
  
}

.give-away h3{
  font-size: 24px;
}

.about h2{
  font-size: 38px;
  font-weight: 700;
  color: white;
  padding-bottom: 35px;
}

.about p{
  color: white;
  line-height: 35px;
  padding-bottom: 20px;
}



.about .btn-podcast{
  background: transparent;
  border: 2px solid white;
  color: white;
  transition: all 0.5s ease-in-out;
}

.about .btn-podcast:hover{
  background: white;
  color: black;
}

.trend-row .pix2{
  width: 200px;
  height: 200px;
  /* border-radius: 10%; */
  margin: auto;
  overflow: hidden;
}

/* .trend-row .pix2 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  clipPath: 'inset(25% 25% 25% 25%)'
} */

.trend-row h2{
  font-size: 20px;
  font-weight: 600;
  padding: 15px 0 12px;
  text-transform: capitalize;
  color: #6c757d;
}

.trend-row span{
  font-size: 14px;
  padding-bottom: 10px;
}

.trend-row h4{
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.trend-row .btn-watch{
  background-color: #4378f5;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
}

.host .title{
  font-size: 40px;
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
}

/* .host .title::before{
  content: "";
  position: absolute;
  background-color: #4378f5;
  width: 180px;
  height: 5px;
  top: 49px;
} */

.host h5{
  color: white;
  font-weight: 600;
  margin-bottom: 35px;
}

.host p{
  color: white;
  padding-bottom: 20px;
  line-height: 30px;
  font-weight: lighter !important;
}

.host p#p{
  padding-bottom: 10px;
}

.muted{
  color: #ccc;
}

.contact h2{
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 15px;
}
.contact h1{
  font-size: 50px;
  font-weight: 700;
  padding-bottom: 15px;
  padding-top: 15px;
}
.contact h5{
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 15px;
  padding-top: 40px;
}

.contact .text1{
  padding-bottom: 15px;
  font-size: 15px;
  font-style: italic;
  width: 75%;
}

.contact .text-s{
  font-size: 14px;
}

.contact label{
  font-size: 15px;
  padding-bottom: 5px;
}

.contact input{
  background-color: #e6e6e6;
  border-radius: 10px;
}
.contact textarea{
  background-color: #e6e6e6;
  border-radius: 10px;
}

.foundation h2{
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 3px;
  padding-bottom: 40px;
  color: white;
}

.foundation p{
  line-height: 32px;
  padding-bottom: 20px  ;
  color: white;
}

.foundation .text{
  padding-bottom: 40px;
  color: white;
}

.foundation .pix{
  width: 450px;
  height: 450px;
  margin: auto;
  overflow: hidden;
}



.foundation .pix img{
  height: 100%;
  object-fit: cover;
}

.foundation .image h3{
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 15px;
}

.foundation input{
  border-radius: 15px;
}

.foundation .image p{
  font-size: 14px;
  font-weight: lighter !important;
  line-height: 20px;
}

.contacts .title{
  padding-bottom: 20px;
  font-size: 35px;
  font-weight: 600;
  position: relative;
  width: 250px;
  margin: auto;
}

.contacts .title::before{
  content: "";
  width: 180px;
  height: 5px;
  background-color: #4378f5;
  position: absolute;
  bottom: 15px;
  left: 37px;
}

.footer .pix{
  width: 100%;
  height: 100px;
  margin: auto;
  overflow: hidden;
}

.footer .pix img{
  height: 100%;
  object-fit: cover;
}

@media(max-width: 576px){
  .topp{
    height: 65vh;
  }

  .topp h1{
    font-size: 45px;
    padding-bottom: 15px !important;
  }

  .topp .new{
    padding-bottom:  15px;
  }

  .foundation .pix{
    width: 250px;
    height: 250px;
    margin: 0;
    overflow: hidden;
  }

  .give-away h3{
    font-size: 14px;
  }

  .shop{
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 25px;
    cursor: pointer;
  }
  @media(max-width: 565px){
    .shop{
      padding: 10px 70px;
    }

    .give-away h3{
      margin-bottom: 15px;
    }
  }
  @media(max-width: 360px){
    .shop{
      padding: 10px 50px;
    }
  }
  .trend h2{
    font-size: 25px;
  }
  .trend p{
    font-size: 12px;
  }

  .right h4{
    width: 90px;
  }

  .contact h1{
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .contact h5{
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
    padding-top: 10px;
  }
  .contact .text1{
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 15px;
    font-style: italic;
    width: 100%;
  }
  .foundation .pix{
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
  }
}

.fa-spin {
  display: inline;
  animation: spin 1s linear infinite; /* Apply the spinning animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}