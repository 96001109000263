.dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  .loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    border-radius: 50%;
    -webkit-animation: dot-pulse2 1.5s ease-in-out infinite;
    animation: dot-pulse2 1.5s ease-in-out infinite;
  }
  
  .dot-1 {
    background-color: #4285f4;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .dot-2 {
    background-color: #34a853;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .dot-3 {
    background-color: #fbbc05;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .dot-4 {
    background-color: #ea4335;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  
  .dot-5 {
    background-color: #4285f4;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  
  @keyframes dot-pulse2 {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0.5;
    }
  
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0.5;
    }
  }
  